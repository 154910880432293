<script lang="ts">
  import classnames from "classnames";
  import { omit } from "$lib/utils/object";

  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  // Assets
  import spinner from "$lib/assets/animated/spinner.svg";

  // Default Props
  export let variant: "fill" | "outline" | "icon" = "fill";
  export let color: "pink" | "grey" | "white" = "pink";

  export let fullWidth = false;
  export let disabled = false;
  export let compact = false;
  export let isLoading = false;
  export let noMinWidth = false;
  export let minWidthSmall = false;
  export let icon: string | null = null;
  export let href: string | null = null;

  const classNames = classnames($$props.class, {
    button: true,
    "v-fill": variant === "fill",
    "v-outline": variant === "outline",
    "v-icon": variant === "icon",
    "v-compact": compact,
    "v-no-min-width": noMinWidth,
    "v-min-width-small": minWidthSmall,
    "c-pink": color === "pink",
    "c-grey": color === "grey",
    "c-white": color === "white",
    "s-disabled": disabled,
    "full-width": fullWidth,
  });
  const attributes = omit($$restProps, "class");
</script>

{#if href}
  <a {href} class={classNames} {...attributes}>
    {#if isLoading}
      <img src={spinner} width="20" height="20" />
    {:else}
      {#if icon}
        <span class="btn-icon">
          <Icon icon={icon} />
        </span>
      {/if}
      {#if $$slots["default"]}
        <span class="btn-text">
          <slot />
        </span>
      {/if}
    {/if}
  </a>
{:else}
  <button class={classNames} {...attributes} {disabled} on:click>
    {#if isLoading}
      <img src={spinner} width="20" height="20" />
    {:else}
      {#if icon}
        <span class="btn-icon">
          <Icon icon={icon} />
        </span>
      {/if}
      {#if $$slots["default"]}
        <span class="btn-text">
          <slot />
        </span>
      {/if}
    {/if}
  </button>
{/if}

<style type="text/scss" lang="scss" scoped>
  .button {
    --button-min-width: 150px;

    @apply appearance-none;
    @apply inline-flex justify-center flex-shrink-0 items-center max-w-full;
    @apply bg-transparent;
    @apply uppercase font-semibold;
    @apply text-sm md:text-base text-center no-underline tracking-wide;
    @apply rounded border border-transparent border-solid;
    @apply px-7;
    @apply h-button;
    @apply cursor-pointer select-none;
    @apply transition-colors ease-in-out duration-300;

    &:not(.v-icon):not(.v-no-min-width) {
      min-width: var(--button-min-width);
    }

    &.v-min-width-small {
      --button-min-width: 100px;
    }

    /* Disabled */
    @apply disabled:cursor-default;

    /* Full width */
    &.full-width {
      @apply w-full;
    }
  }

  .btn-text {
    @apply truncate;
  }

  /* Icon variant */
  .btn-icon {
    @apply inline-flex;
  }
  .btn-icon + .btn-text {
    @apply ml-2;
  }

  /* Variants */
  .v-fill {
    &.c-pink {
      @apply bg-brand border-brand text-gray-900;

      /* Hover */
      @apply transition-colors hover:bg-brand-dark hover:border-brand-dark;
      /* Focus */
      @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand focus:ring-opacity-30;
      /* Disabled */
      @apply disabled:bg-opacity-30 disabled:bg-brand disabled:transition-none disabled:transform-none disabled:border-transparent disabled:text-opacity-30;
    }
    &.c-grey {
      @apply bg-gray-900 border-gray-900 text-white;
      /* Hover */
      @apply hover:bg-black hover:border-black hover:text-white;
      /* Focus */
      @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-black;
      /* Disabled */
      @apply disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-700;
    }

    &.c-white {
      @apply bg-white border-white text-gray-900;
      /* Hover */
      @apply transition-colors hover:bg-opacity-80 hover:border-opacity-80;
      /* Focus */
      @apply focus:outline-none focus:ring-4 focus:ring-white focus:ring-opacity-30;
      /* Disabled */
      @apply disabled:bg-opacity-30 disabled:transition-none disabled:transform-none disabled:border-transparent;
    }
  }

  .v-outline {
    @apply bg-transparent;

    &.c-pink {
      @apply border-brand text-gray-900;
      /* Hover */
      @apply hover:bg-brand hover:bg-opacity-20;
      /* Focus */
      @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-petrol;
      /* Disabled */
      @apply disabled:bg-transparent disabled:border-brand disabled:border-opacity-30 disabled:text-gray-300;
    }
    &.c-grey {
      @apply border-gray-700 text-gray-900;
      /* Hover */
      @apply hover:bg-gray-900 hover:bg-opacity-10;
      /* Focus */
      @apply focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-black;
      /* Disabled */
      @apply disabled:bg-transparent disabled:border-gray-300 disabled:text-gray-300;
    }

    &.c-white {
      @apply bg-transparent border-white text-white;
      /* Hover */
      @apply hover:bg-white hover:bg-opacity-20;
      /* Focus */
      @apply focus:outline-none focus:ring-4 focus:ring-white focus:ring-opacity-30;
      /* Disabled */
      @apply disabled:bg-transparent disabled:border-opacity-50 disabled:text-white disabled:text-opacity-50;
    }
  }

  .v-compact {
    @apply text-sm;
    @apply py-2.5;
    @apply h-10;
  }

  .v-icon {
    @apply bg-transparent;
    @apply p-2 h-auto;
    @apply transition-opacity;
    @apply hover:opacity-60;
    @apply disabled:opacity-40 disabled:cursor-not-allowed;

    &.c-pink {
      @apply text-brand;
    }
    &.c-grey {
      @apply text-gray-900;
    }
    &.c-white {
      @apply text-white;
    }
  }
</style>
